<template>
  <div class="cutaway">
    <div class="title"></div>
    <div class="crumb"></div>
    <div class="content">
      <div class="actions">
        <div class="search">
          <a-input-search
            class="search-input"
            placeholder="请输入您要查找的内容"
            v-model="searchName"
            @search="onSearch"
          />
        </div>
        <div class="operation">
          <a-button class="add" type="primary" @click="showGridsetsDialog(`new`, null)"
            >添加新的切图方案</a-button
          >
          <a-button class="delete" type="danger" @click="deleteSelectedGridsets"
            >删除所选切图方案</a-button
          >
        </div>
      </div>
      <a-card class="table">
        <a-table :columns="columns" :data-source="showData" :row-selection="rowSelection">
          <span slot="name" slot-scope="text, record">
            <a @click="showGridsetsDialog(`edit`, record)">{{ text }}</a>
          </span>
          <span slot="createCopy" slot-scope="text">
            <a @click="copy(text)">复制该方案</a>
          </span>
        </a-table>
      </a-card>
    </div>
    <gridset-info
      :visible.sync="visible"
      :editData="editData"
      @refreshGridsets="refreshGridsets"
    ></gridset-info>
  </div>
</template>
<script>
const columns = [
  {
    title: "名称",
    dataIndex: "name",
    key: "name",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "坐标参考系统",
    dataIndex: "crs",
    key: "crs",
  },
  {
    title: "瓦片大小",
    dataIndex: "tileDimensions",
    key: "tileDimensions",
  },
  {
    title: "缩放等级",
    dataIndex: "zoomLevels",
    key: "zoomLevels",
  },
  {
    title: "",
    dataIndex: "name",
    key: "createCopy",
    scopedSlots: { customRender: "createCopy" },
  },
];

import { getGridsetsTree, copyGridset, delGridset, } from "@/api/data/gridsets.js";
import GridsetInfo from "./GridsetInfo";

export default {
  components: { GridsetInfo },
  data() {
    return {
      searchName: null,
      data: [],
      showData: [],
      columns,
      selectedRows: [],
      visible: false,
      editData: null,
    };
  },
  methods: {
    onSearch() {
      if (this.searchName && this.searchName !== "") {
        this.showData = this.data.filter((p) => {
          return (
            p.name.indexOf(this.searchName) !== -1 ||
            p.crs.indexOf(this.searchName) !== -1
          );
        });
      } else {
        this.refreshGridsets();
      }
    },
    showGridsetsDialog(type, data) {
      if (type == `edit`) {
        this.editData = data;
        this.visible = true;
      } else {
        this.editData = null;
        this.visible = true;
      }
    },
    deleteSelectedGridsets() {
      if (this.selectedRows.length == 0) {
        this.$message.info("未选择任何切图方案");
      } else {
        this.selectedRows.map((item) => {
          delGridset(item.name).then((res) => {
            if (res.status == 200) {
              this.$message.success(`已删除切图方案${item.name}`);
            }
            this.refreshGridsets();
          });
        });
      }
    },
    copy(name) {
      copyGridset(name).then((res)=>{
        if(res.status==200){
          this.$message.success("复制切图方案成功");
          this.refreshGridsets();
        }
      });
    },
    refreshGridsets() {
      getGridsetsTree().then((res) => {
        this.data = res.data.map((item) => {
          item.key = item.id;
          item.tileDimensions = item.tileHeight + ` x ` + item.tileWidth;
          return item;
        });
        this.showData = this.data;
      });
    },
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRows = selectedRows;
        },
      };
    },
  },
  mounted() {
    this.refreshGridsets();
  },
};
</script>

<style scoped>
.actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
}
.search-input {
  width: 505px;
  height: 48px;
  background: #ffffff;
  border-radius: 24px;
}

.add,
.delete {
  width: 163px;
  height: 48px;
  border-radius: 4px;
  margin-right: 19px;
}
</style>
<style>
.cutaway .search-input .ant-input{
  width: 505px;
  height: 48px;
  background: #ffffff;
  border-radius: 24px;
}

.cutaway .search-input .ant-input-suffix{
  font-size: 18px;
}
</style>
