import http from '@/config/axios.config'

// 得到srs列表
export function getSRSList() {
    return http({
        url: `/rest/betaGISUI/SRS/list`,
        method: "get",
    })
}

//通过srscode得到bounds
export function computeBoundsFromSRS(code) {
    return http({
        url: `/rest/betaGISUI/SRS/${code}/bounds`,
        method: "get",
    })
}