import http from '@/config/axios.config'

// 得到切图方案首页信息
export function getGridsetsTree() {
    return http({
        url: `/rest/betaGISUI/GWC/gridSets/tree`,
        method: "get",
    })
}

// 得到切图方案信息
export function getGridsetInfo(name) {
    return http({
        url: `/rest/betaGISUI/GWC/gridSet/${name}`,
        method: "get",
    })
}

// 得到瓦片矩阵集合
export function getNewGridMatrixSet({name,srs,minx,miny,maxx,maxy,levels,width,height,tileWidth,tileHeight}) {
    return http({
        url: `/rest/betaGISUI/GWC/gridMatrixSet`,
        method: "get",
        params: {
            name,srs,minx,miny,maxx,maxy,levels,width,height,tileWidth,tileHeight
        }
    })
}   

// 复制切图方案信息
export function copyGridset(name) {
    return http({
        url: `/rest/betaGISUI/GWC/copy/gridSet/${name}`,
        method: "post",
    })
}

// 创建或更新切图方案
export function putGridset(name, data) {
    return http({
        url: `/rest/betaGISUI/GWC/gridSet/${name}`,
        method: "put",
        data: data
    })
}

// 删除切图方案
export function delGridset(gridsetName) {
    return http({
        url: `/gwc/rest/gridsets/${gridsetName}`,
        method: "delete",
    })
}