<template>
  <a-modal :visible="srsSelectionVisible" @ok="cancel" @cancel="cancel">
    <a-input-search
      placeholder="搜索编码"
      enter-button
      v-model="searchName"
      @search="onSearch"
      style="padding: 20px 0px 10px 0px"
    ></a-input-search>
    <a-table :columns="columns" :data-source="showList">
      <span slot="code" slot-scope="text">
        <a @click="setSRS(text)">{{ text }}</a>
      </span>
      <span slot="description" slot-scope="text, record">
        <a @click="setSRS(record.code)">{{ text }}</a>
      </span>
    </a-table>
  </a-modal>
</template>
<script>
const columns = [
  {
    title: "编码",
    dataIndex: "code",
    key: "code",
    scopedSlots: { customRender: "code" },
  },
  {
    title: "说明",
    dataIndex: "description",
    key: "description",
    scopedSlots: { customRender: "description" },
  },
];
import { getSRSList } from "@/api/data/srs.js";
export default {
  data() {
    return {
      searchName: "",
      columns,
      srsList: [],
      showList: [],
    };
  },
  props: ["srsSelectionVisible"],
  methods: {
    onSearch() {
      if (this.searchName && this.searchName !== "") {
        this.showList = this.srsList.filter(
          (p) => p.code.indexOf(this.searchName) !== -1
        );
      } else {
        this.refreshData();
      }
    },
    setSRS(text) {
      this.$emit("setSRSCode", text);
      this.cancel();
    },
    cancel() {
      this.$emit("update:srsSelectionVisible", false);
    },
    refreshData() {
      getSRSList().then((res) => {
        this.srsList = res.data.map((item) => {
          item.key = item.code;
          return item;
        });
        this.showList = this.srsList;
      });
    },
  },
  mounted() {
    this.refreshData();
  },
};
</script>