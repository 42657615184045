<template>
  <a-modal :visible="visible" @ok="createOrUpdateGridset" @cancel="cancel">
    <a-form :form="form">
      <a-form-item label="名称">
        <a-input :maxLength="20" v-decorator="['name', ValidateRules.name]" />
      </a-form-item>
      <a-form-item label="描述">
        <a-textarea v-model="gridsetParam.description" :rows="4" />
      </a-form-item>
      <div style="display: flex; justify-content: space-between">
        <a-form-item label="坐标参考系统" style="display: flex">
          <a-input :maxLength="30" v-decorator="['crs', ValidateRules.crs]" />
        </a-form-item>
        <a-button type="primary" @click="showSRSSelectionDialog">查找</a-button>
      </div>
      <a-form-item label="边界范围">
        <div class="border">
          <a-form-item label="最小 X">
            <a-input-number
              :maxLength="30"
              v-decorator="['minx', ValidateRules.minx]"
            />
          </a-form-item>
          <a-form-item label="最小 Y">
            <a-input-number
              :maxLength="30"
              v-decorator="['miny', ValidateRules.miny]"
            />
          </a-form-item>
          <a-form-item label="最大 X">
            <a-input-number
              :maxLength="30"
              v-decorator="['maxx', ValidateRules.maxx]"
            />
          </a-form-item>
          <a-form-item label="最大 Y">
            <a-input-number
              :maxLength="30"
              v-decorator="['maxy', ValidateRules.maxy]"
            />
          </a-form-item>
        </div>
      </a-form-item>
      <a-form-item label="瓦片尺寸(像素)">
        <div class="tilesize">
          <a-form-item label="宽度:" style="display: flex">
            <a-input-number
              :maxLength="30"
              v-decorator="['tileWidth', ValidateRules.tileWidth]"
            />
          </a-form-item>
          <a-form-item label="高度:" style="display: flex">
            <a-input-number
              :maxLength="30"
              v-decorator="['tileHeight', ValidateRules.tileHeight]"
            />
          </a-form-item>
        </div>
      </a-form-item>
      <a-form-item>
        <a-table :columns="columns" :data-source="gridsetParam.matrixSetData">
          <!-- <span slot="name" slot-scope="text, record">
            <a-input-number
              :maxLength="30"
              v-decorator="['tileWidth', ValidateRules.tileWidth]"
            />
          </span> -->
        </a-table>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" block @click="addMatrixSet"
          >+添加缩放级别</a-button
        >
      </a-form-item>
      <a-form-item>
        <a-button type="danger" block @click="delMatrixSet"
          >-删除缩放级别</a-button
        >
      </a-form-item>
    </a-form>
    <srs-selection
      :srsSelectionVisible.sync="srsSelectionVisible"
      @setSRSCode="setSRSCode"
    ></srs-selection>
  </a-modal>
</template>
<script>
import { computeBoundsFromSRS } from "@/api/data/srs.js";
import {
  getGridsetInfo,
  getNewGridMatrixSet,
  putGridset,
} from "@/api/data/gridsets.js";
import srsSelection from "../common/srsSelection";
const columns = [
  {
    title: "等级",
    dataIndex: "level",
    key: "level",
    scopedSlots: { customRender: "level" },
  },
  {
    title: "像素大小",
    dataIndex: "resolution",
    key: "resolution",
    scopedSlots: { customRender: "resolution" },
  },
  {
    title: "缩放",
    dataIndex: "scale",
    key: "scale",
    scopedSlots: { customRender: "scale" },
  },
  {
    title: "名称",
    dataIndex: "name",
    key: "name",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "瓦片",
    dataIndex: "tiles",
    key: "tiles",
    scopedSlots: { customRender: "tiles" },
  },
];
export default {
  components: { srsSelection },
  data() {
    return {
      form: this.$form.createForm(this),
      columns,
      srsSelectionVisible: false,
      gridsetParam: {
        description: "",
        bounds: {}, 
        matrixSetData: [],
      },
      ValidateRules: {
        name: { rules: [{ required: true, message: "请输入工作空间名称" }] },
        crs: {
          rules: [{ required: true, message: "请输入正确的参考坐标系统" }],
        },
        minx: {
          rules: [{ required: true, message: "请输入边界范围最小X" }],
        },
        miny: {
          rules: [{ required: true, message: "请输入边界范围最小Y" }],
        },
        maxx: {
          rules: [{ required: true, message: "请输入边界范围最大X" }],
        },
        maxy: {
          rules: [{ required: true, message: "请输入边界范围最大Y" }],
        },
        tileWidth: {
          initialValue: 256,
          rules: [{ required: true, message: "请输入瓦片宽度" }],
        },
        tileHeight: {
          initialValue: 256,
          rules: [{ required: true, message: "请输入瓦片高度" }],
        },
      },
    };
  },
  props: ["visible", "editData"],
  watch: {
    visible() {
      if (this.visible && this.editData) {
        getGridsetInfo(this.editData.name).then((res) => {
          this.gridsetParam = res.data;
          this.$nextTick(() => {
            this.form.setFieldsValue({
              name: this.gridsetParam.name,
              crs: this.gridsetParam.crs,
              tileWidth: this.gridsetParam.tileWidth,
              tileHeight: this.gridsetParam.tileHeight,
              minx: this.gridsetParam.bounds.minx,
              miny: this.gridsetParam.bounds.miny,
              maxx: this.gridsetParam.bounds.maxx,
              maxy: this.gridsetParam.bounds.maxy,
            });
          });
        });
      }
    },
  },
  methods: {
    createOrUpdateGridset() {
      this.form.validateFields((err, values) => {
        if (!err) {
          if(this.gridsetParam.matrixSetData.length==0){
            this.$message.error(
                `至少需要添加一级缩放级别`
              );
              return;
          }
          putGridset(this.form.getFieldValue("name"), {
            name: values.name,
            description: this.gridsetParam.description,
            srs: values.crs,
            minx: values.minx,
            miny: values.miny,
            maxx: values.maxx,
            maxy: values.maxy,
            numLevels: this.gridsetParam.matrixSetData.length,
            resolutions: this.gridsetParam.matrixSetData.map((item) => {
              return item.resolution;
            }),
            scale: this.gridsetParam.matrixSetData.map((item) => {
              return item.scale;
            }),
            metersPerUnit: this.gridsetParam.metersPerUnit,
            pixelSize: 0.00028,
            scaleNames: this.gridsetParam.matrixSetData.map((item) => {
              return item.name;
            }),
            tileHeight: values.tileHeight,
            tileWidth: values.tileWidth,
            yCoordinateFirst: this.gridsetParam.yCoordinateFirst,
          }).then((res) => {
            if (res.status == 200) {
              this.$message.success(
                `已更新切图方案${this.form.getFieldValue("name")}`
              );
              this.success();
            } else if (res.status == 201) {
              this.$message.success(
                `已创建切图方案${this.form.getFieldValue("name")}`
              );
              this.success();
            } else {
              this.cancel();
            }
          });
        }
      });
    },

    cancel() {
      this.$emit("update:visible", false);
      this.clean();
    },
    showSRSSelectionDialog() {
      this.srsSelectionVisible = true;
    },
    setSRSCode(srs) {
      this.form.setFieldsValue({
        crs: `EPSG:` + srs,
      });
      computeBoundsFromSRS(srs).then((res) => {
        if (res.status == 200) {
          this.form.setFieldsValue({
            minx: res.data.minx,
            miny: res.data.miny,
            maxx: res.data.maxx,
            maxy: res.data.maxy,
          });
          this.gridsetParam.metersPerUnit = res.data.metersPerUnit;
          this.gridsetParam.bounds.width = res.data.width;
          this.gridsetParam.bounds.height = res.data.height;
          this.gridsetParam.yCoordinateFirst = res.data.yCoordinateFirst;
          if (this.gridsetParam.matrixSetData.length != 0) {
            this.gridsetParam.matrixSetData = [];
          }
          this.addMatrixSet();
        }
      });
    },
    addMatrixSet() {
      this.form.validateFields((err, values) => {
        if (!err) {
          getNewGridMatrixSet({
            name: values.crs,
            minx: values.minx,
            miny: values.miny,
            maxx: values.maxx,
            maxy: values.maxy,
            levels: this.gridsetParam.matrixSetData.length + 1,
            width: this.gridsetParam.bounds.width,
            height: this.gridsetParam.bounds.height,
            tileWidth: values.tileWidth,
            tileHeight: values.tileHeight,
          }).then((res) => {
            this.gridsetParam.matrixSetData = res.data;
          });
        }
      });
    },
    delMatrixSet(){
      this.gridsetParam.matrixSetData.pop();
    },
    success() {
      this.$emit("update:visible", false);
      this.$emit("refreshGridsets");
      this.clean();
    },
    clean() {
      this.gridsetParam = {
        description: "",
        bounds: {}, 
        matrixSetData: [],
      };
      this.form.setFieldsValue({
        name: "",
        crs: "",
        tileWidth: 256,
        tileHeight: 256,
        minx: "",
        miny: "",
        maxx: "",
        maxy: "",
      });
    },
  },
  mounted() {},
};
</script>
<style scoped>
.border,
.tilesize {
  display: flex;
  justify-content: space-between;
}
</style>